<template>
  <v-row class="changePassword pt-4 pt-sm-4 pt-md-0">
    <v-col
      cols="12"
      sm="12"
      md="6"
      class="primary d-none d-sm-none d-md-flex align-center justify-center text-center"
    >
      <v-container>
        <router-link to="/">
          <img
            src="@/assets/foctus-logo-whitebg-newbg.png"
            alt="Focts Logo"
            width="300"
            style="border: 0"
          />
        </router-link>
        <div class="taglines pa-1">
          <h4 class="heading text-uppercase white--text">Organize</h4>
          <hr />
          <h4 class="heading text-uppercase white--text">
            INCREASE PRODUCTIVITY
          </h4>
          <hr />
          <h4 class="heading text-uppercase white--text">Manage Team</h4>
        </div>
      </v-container>
    </v-col>
    <v-col
      cols="12"
      class="d-flex d-sm-flex d-md-none align-center justify-center stick-header-logo"
    >
      <router-link to="/" class="center aligned pa-5 pb-0 text-decoration-none">
        <img
          src="@/assets/logo-new-1-bg.png"
          alt="Focts Logo"
          width="150"
          style="border: 0"
        />
      </router-link>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      class="auth-right-column mobile-content-logo d-flex align-center justify-center"
      v-if="Emailaddress"
    >
      <v-container class="pa-2 pa-sm-2 pa-md-10">
        <v-text-field
          :error-messages="errors.collect('forgot-paasword.Emailaddress')"
          v-model="Emailaddress"
          readonly
          v-validate="'required|email'"
          data-vv-scope="forgot-paasword"
          label="Email Address"
          data-vv-as="email address"
          name="Emailaddress"
          type="email"
        ></v-text-field>
        <v-text-field
          :append-icon="shownewpasswordtext ? 'visibility' : 'visibility_off'"
          :type="shownewpasswordtext ? 'text' : 'password'"
          @click:append="shownewpasswordtext = !shownewpasswordtext"
          v-model="newpassword"
          ref="password"
          label="New Password"
          :error-messages="errors.collect('forgot-paasword.newpassword')"
          data-vv-name="newpassword"
          data-vv-as="new password"
          data-vv-scope="forgot-paasword"
          v-validate="'required|min:6'"
        ></v-text-field>
        <v-text-field
          :append-icon="
            showconfirmpasswordtext ? 'visibility' : 'visibility_off'
          "
          :type="showconfirmpasswordtext ? 'text' : 'password'"
          @click:append="showconfirmpasswordtext = !showconfirmpasswordtext"
          v-model="confirmpassword"
          label="Confirm Password"
          :error-messages="errors.collect('forgot-paasword.confirmpassword')"
          data-vv-name="confirmpassword"
          data-vv-as="confirm password"
          data-vv-scope="forgot-paasword"
          v-validate="'required|min:6|confirmed:password'"
        ></v-text-field>
        <v-row>
          <v-col>
            <v-btn
              :disabled="disablestatus"
              block
              type="submit"
              class="mt-2 white--text gradient-button"
              tile
              color="blue"
              @click="changePassword()"
              >Submit</v-btn
            >
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      v-else-if="load"
      class="d-flex text-center align-center justify-center mobile-content-logo"
    >
      <div class="horizontal-dividing-header pa-5 pa-md-10">
        <h3 class="header">Error</h3>
        <h6 class="header">Password reset link expired</h6>
      </div>
    </v-col>
    <v-snackbar v-model="showSnackBar" :bottom="true" color="error">{{
      snackMessage
    }}</v-snackbar>
  </v-row>
</template>

<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
import moment from "moment";
Vue.use(VeeValidate);
export default {
  data: () => ({
    disablestatus: false,
    moment: moment,
    load: false,
    showSnackBar: false,
    snackMessage: "",
    userDetail: "",
    newpassword: "",
    confirmpassword: "",
    Emailaddress: "",
    shownewpasswordtext: false,
    showconfirmpasswordtext: false,
  }),
  mounted() {
    this.getUserDetail();
  },
  methods: {
    getUserDetail() {
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "entrance/get-passworduser-token/" +
            this.$route.query.token
        )
        .then((res) => {
          this.load = true;
          this.Emailaddress = res.data.emailAddress;
          this.userDetail = res.data;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    setintolocalstorage(response) {
      var self = this;
      this.$store
        .dispatch("account/storeLoginDetails", response.data)
        .then(() => {
          self.$http.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.token;
          var authuser = JSON.parse(localStorage.getItem("user"));

          if (authuser.isAdmin) {
            window.location.replace("/subscriptionflows");
          } else {
            window.location.replace("/mywork?companyId=" + authuser.companyId);
          }
        });
    },
    changePassword() {
      if (this.disablestatus) {
        return true;
      }
      this.disablestatus = true;
      var self = this;
      this.$validator
        .validateAll("forgot-paasword")
        .then((res) => {
          if (res) {
            var postdata = {
              userid: self.userDetail.id,
              newpassword: self.newpassword,
            };
            this.$http
              .post(
                process.env.VUE_APP_ROOT_API +
                  "entrance/update-forgot-password",
                postdata
              )
              .then((response) => {
                localStorage.setItem("iniatialQuickStart", false);
                self.$store
                  .dispatch("account/storeLoginDetails", response.data)
                  .then(() => {
                    if (response.data.user.isAdmin) {
                      self.setintolocalstorage(response);
                    } else {
                      var subscribedstatus = false;
                      if (
                        response.data.returncompany.trialcompletedDate >=
                        new Date().getTime()
                      ) {
                        subscribedstatus = true;
                      } else {
                        if (response.data.returncompany.subscriptionId) {
                          if (response.data.returncompany.subscription) {
                            if (
                              response.data.returncompany.subscription
                                .billing_reason == "admin created subscription"
                            ) {
                              if (
                                moment().unix() >
                                  response.data.returncompany.subscription
                                    .subscriptionEndTimestamp ||
                                response.data.returncompany
                                  .SubscirptionStatus == "canceled"
                              ) {
                                subscribedstatus = false;
                              } else {
                                subscribedstatus = true;
                              }
                            } else {
                              if (
                                response.data.returncompany
                                  .SubscirptionStatus === "canceled" ||
                                response.data.returncompany
                                  .SubscirptionStatus === "draft" ||
                                response.data.returncompany
                                  .SubscirptionStatus === "active"
                              ) {
                                if (
                                  response.data.returncompany.subscription
                                    .subscriptionEndTimestamp >=
                                    moment().unix() &&
                                  response.data.returncompany.subscription
                                    .billing_reason ==
                                    "admin created subscription"
                                ) {
                                  subscribedstatus = true;
                                } else {
                                  if (
                                    response.data.returncompany
                                      .SubscirptionStatus === "active" ||
                                    response.data.returncompany
                                      .SubscirptionStatus === "trialing"
                                  ) {
                                    subscribedstatus = true;
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                      if (subscribedstatus === true) {
                        self.setintolocalstorage(response);
                      } else {
                        this.$store
                          .dispatch("account/storeLoginDetails", response.data)
                          .then(() => {
                            self.$http.defaults.headers.common[
                              "Authorization"
                            ] = "Bearer " + response.data.token;
                            var authuser = JSON.parse(
                              localStorage.getItem("user")
                            );
                            self.$store.dispatch(
                              "account/getUsersubscriptionStatus",
                              { authuser }
                            );
                            window.location.replace(
                              "/trial-expired?companyId=" +
                                response.data.user.companyId
                            );
                          });
                      }
                    }
                  })
                  .catch((error) => {
                    self.messagecolor = "red";
                    this.disableSubmit = false;
                    if (
                      error.response &&
                      error.response.data &&
                      error.response.data.message
                    ) {
                      self.snackMessage = error.response.data.message;
                      if (
                        error.response.data.errortype &&
                        error.response.data.errortype == "verifyerror"
                      ) {
                        self.timeout = 4000;
                        self.resendverifyuserid = error.response.data.userid;
                      }
                      self.$nextTick(() => {
                        self.showSnackBar = true;
                      });
                    } else {
                      self.snackMessage = "Incorrect password";
                      self.$nextTick(() => {
                        self.showSnackBar = true;
                        this.disableSubmit = false;
                      });
                    }
                  });
              })
              .catch(() => {
                this.disablestatus = false;
              });
          } else {
            this.disablestatus = false;
          }
        })
        .catch(() => {
          this.disablestatus = false;
        });
    },
    restinputs() {
      this.oldpassword = "";
      this.newpassword = "";
      this.confirmpassword = "";
      this.$nextTick(() => {
        this.$validator.reset();
      });
    },
  },
};
</script>

<style scoped>
@media (max-width: 991px) {
  .row.changePassword {
    flex: none;
  }
}
</style>
