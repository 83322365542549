<template>
  <div>
    <div 
    @click="gotologin" 
      class="text-decoration-none d-flex justify-center align-center pt-3 header-error-static"
      :class="{
        primary: !$vuetify.theme.dark,
        primary: $vuetify.theme.dark,
      }"
    >
      <img
        src="@/assets/dark-mode-logo.png"
        alt="Focts Logo"
        class="error-page"
        v-if="$vuetify.theme.dark"
        style="border: 0"
      />
      <img
        v-else
        src="@/assets/logo-new-1-bg.png"
        alt="Focts Logo"
        class="error-page"
        style="border: 0"
      />
    </div>
    <div class="d-flex justify-center align-center error-page-height">
      <v-card elevation="0" class="transparent">
        <v-card-text>
          <h1
            v-if="this.errorcode"
            class="font-weight-bold black--text text-center mb-0 error-code-font"
          >
            {{ errorcode }}
          </h1>
          <h1
            v-else
            class="font-weight-bold black--text text-center mb-0 error-code-font"
          >
            403
          </h1>
        </v-card-text>
        <v-card-title class="d-flex justify-center pt-0">
          <div
            v-if="this.message"
            class="first-character text-h5 font-weight-bold"
          >
            {{ message }}
          </div>
          <div v-else>Un Authorized</div>
        </v-card-title>
        <v-card-actions class="d-flex justify-center">
          <v-btn @click="gotologin" outlined>Go Home</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </div>
</template>
<script>
export default {
  data: () => ({
    message: "",
    errorcode: "",
    windowHeight: window.innerHeight - 80,
    windowMobileHeight: window.innerHeight - 100,
    windowWidth: window.innerWidth,
  }),
  mounted() {
    this.message = this.$route.query.message;
    this.errorcode = this.$route.query.statuscode;
  },
  methods: {
    gotologin() {
      var authuser = localStorage.getItem("user");
      var companyid = "";
      if (authuser) {
        companyid = JSON.parse(localStorage.getItem("user")).companyId;
      }
      window.location.replace("/mywork?companyId=" + companyid);
    },
  },
};
</script>

<style>
img.error-page {
  width: 200px;
}
.error-code-font {
  font-size: 10em;
}
.header-error-static {
  position: fixed;
  width: 100%;
  border-bottom: 1px solid #ccc;
}

.error-page-height {
  padding-top: 10em;
}

@media (max-width: 767px) {
  .error-code-font {
    font-size: 5em;
  }
  .error-page-height {
    padding-top: 6em;
  }
}
</style>
