<template>
  <v-row no-gutters class="align-center justify-center subscription-page">
    <v-card
      elevation="1"
      class="pa-1 d-flex align-center justify-center flex-column ma-2"
      v-if="loader"
    >
      <div>
        <v-icon color="primary" style="font-size: 100px">sync_problem</v-icon>
      </div>
      <v-card-title
        v-if="
          user.allcompanies.find((data) => data.id == $route.query.companyId)
            .isAdmin
        "
      >
        <div
          v-if="
            company.subscription &&
            company.subscription.billing_reason == 'admin created subscription'
          "
        >
          <span
            v-if="
              current_Timestamp >
                company.subscription.subscriptionEndTimestamp ||
              company.SubscirptionStatus == 'canceled'
            "
          >
            Your subscription has expired
          </span>
        </div>
        <div v-else>
          <span v-if="company.SubscirptionStatus == 'canceled'"
            >Your subscription has been cancelled</span
          >
          <span
            v-else-if="
              company.SubscirptionStatus == 'open' ||
              company.SubscirptionStatus == 'incomplete' ||
              company.SubscirptionStatus == 'past_due' ||
              company.SubscirptionStatus == 'unpaid' ||
              company.SubscirptionStatus == 'incomplete_expired'
            "
          >
            Failed processing your payment
            <router-link
              :to="url"
              style="text-decoration: none"
              class="text-body-2 black--text"
              >update card</router-link
            >
          </span>
          <span v-else>Your 30 days trial period has ended</span>
        </div>
      </v-card-title>
      <v-card-title v-else class="first-character">
        <span v-if="company.SubscirptionStatus"
          >Subscription of your company has cancelled</span
        >
        <span v-else>Subscription of your company has expired</span>
      </v-card-title>
      <p
        v-if="
          user.allcompanies.find((data) => data.id == $route.query.companyId)
            .isAdmin
        "
        class="text-body-2 pl-4 pr-4"
      >
        You can continue to use our service by subscribing to a plan of ours.
      </p>
      <p v-else class="text-body-2 pl-4 pr-4">
        Please contact your company admin.
      </p>
      <v-card-actions
        v-if="
          user.allcompanies.find((data) => data.id == $route.query.companyId)
            .isAdmin
        "
        class="justify-center"
      >
        <v-btn color="primary" @click="showallplans">Choose Plan</v-btn>
      </v-card-actions>
    </v-card>
  </v-row>
</template>
<script>
import axios from "axios";
export default {
  data() {
    return {
      company: "",
      current_Timestamp: new Date().getTime(),
      loader: false,
      subscription: "",
    };
  },
  created() {
    this.plans().then(() => {
      this.loader = true;
    });
  },
  computed: {
    user() {
      return JSON.parse(this.$store.state.account.user);
    },
  },
  methods: {
    showallplans() {
      this.$router.push({
        name: "allplans",
        query: {
          companyId: this.$route.query.companyId,
        },
      });
    },
    async plans() {
      const response = await axios.get(
        `${process.env.VUE_APP_ROOT_API}subscription/get-all-plans`
      );
      this.company = response.data.company;
    },
  },
};
</script>
<style>
.subscription-page {
  transform: translate(0%, 50%);
}
@media (max-width: 767px) {
  .subscription-page {
    transform: translate(0%, 20%);
  }
}
</style>
