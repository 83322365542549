<template>
  <v-row class="login-page">
    <v-col
      cols="12"
      class="d-flex d-sm-flex d-md-none align-center justify-center stick-header-logo"
    >
      <router-link to="/" class="center aligned pt-5 pb-0 text-decoration-none">
        <img
          src="@/assets/logo-new-1-bg.png"
          alt="Focts Logo"
          width="150"
          style="border: 0"
        />
      </router-link>
    </v-col>
    <v-col
      cols="12"
      sm="12"
      md="6"
      class="login-left-pane primary align-center justify-center text-center d-none d-sm-none d-md-flex"
    >
      <v-container>
        <router-link to="/">
          <img
            src="@/assets/foctus-logo-whitebg-newbg.png"
            alt="Focts Logo"
            width="300"
            style="border: 0"
          />
        </router-link>
        <div class="taglines pa-1">
          <h4 class="heading text-uppercase white--text">Organize</h4>
          <hr />
          <h4 class="heading text-uppercase white--text">
            INCREASE PRODUCTIVITY
          </h4>
          <hr />
          <h4 class="heading text-uppercase white--text">Manage Team</h4>
        </div>
      </v-container>
    </v-col>

    <v-col
      cols="12"
      sm="12"
      md="6"
      class="auth-right-column mobile-content-logo d-flex align-center justify-center"
      v-if="forgotpasswordstatus"
    >
      <v-container class="pa-5 pa-md-10">
        <v-row>
          <v-btn
            @click="
              forgotpasswordstatus = false;
              forgotpasswordEmailAddress = '';
              forgotpasswordisable = false;
            "
            icon
          >
            <v-icon>keyboard_backspace</v-icon>
          </v-btn>
        </v-row>

        <h3>Forgot Password</h3>
        <v-text-field
          :error-messages="
            errors.collect('forgot-paasword.forgotpasswordEmailAddress')
          "
          v-model="forgotpasswordEmailAddress"
          v-validate="'required|email'"
          data-vv-scope="forgot-paasword"
          label="Email Address"
          data-vv-as="email address"
          name="forgotpasswordEmailAddress"
          type="email"
        >
        </v-text-field>
        <v-btn
          block
          type="submit"
          class="mt-2 white--text gradient-button login-submit-button"
          tile
          color="blue"
          :loading="forgotpasswordisable"
          @click="sendpasswordresetlink"
          >Send Password Reset Link</v-btn
        >
      </v-container>
    </v-col>
    <v-col
      v-else
      class="auth-right-column mobile-content-logo d-flex align-center justify-center"
    >
      <v-container class="pa-5 pa-md-10">
        <div class="horizontal-dividing-header">
          <h3 class="header">LOGIN</h3>
          <hr class="heading" />
        </div>
        <form @submit="submit">
          <v-text-field
            :error-messages="errors.collect('emailAddress')"
            v-model="formData.emailAddress"
            v-validate="'required|email'"
            label="Email Address"
            data-vv-as="email address"
            name="emailAddress"
            type="email"
          ></v-text-field>
          <v-text-field
            :error-messages="errors.collect('password')"
            v-model="formData.password"
            v-validate="'required'"
            :append-icon="showpasswordtext ? 'visibility' : 'visibility_off'"
            :type="showpasswordtext ? 'text' : 'password'"
            @click:append="showpasswordtext = !showpasswordtext"
            label="Password"
            name="password"
            ref="password"
          ></v-text-field>

          <v-btn
            block
            :loading="disableSubmit"
            :disabled="disableSubmit"
            type="submit"
            class="mt-2 white--text gradient-button login-submit-button"
            tile
            color="blue"
            >Login</v-btn
          >
        </form>
        <v-row>
          <v-col>
            <div class="pt-4 d-flex justify-start transparent">
              <div
                @click="
                  forgotpasswordstatus = true;
                  forgotpasswordisable = false;
                "
                :class="{
                  'black--text': !$vuetify.theme.dark,
                  'white--text': $vuetify.theme.dark,
                }"
                style="cursor: pointer"
              >
                Forgot Password?
              </div>
            </div>
          </v-col>
          <v-col>
            <div class="pt-5 d-flex justify-end">
              <div
                @click="redirecttosignup"
                class="task-titleforgotpassowrd"
                :class="{
                  'primary--text': !$vuetify.theme.dark,
                  'black--text': $vuetify.theme.dark,
                }"
              >
                Register Now
              </div>
            </div>
          </v-col>
        </v-row>
      </v-container>
    </v-col>
    <v-snackbar
      v-model="showSnackBar"
      :timeout="timeout"
      :bottom="true"
      :color="messagecolor"
    >
      {{ snackMessage }}
      <div v-if="resendverifyuserid">
        <div
          class="task-titleforgotpassowrd"
          @click="resendVerificationEmail"
          :disabled="disableresendverifybutton"
        >
          <b>Resend verification email</b>
        </div>
      </div>
    </v-snackbar>
  </v-row>
</template>

<script>
import Vue from "vue";
import moment from "moment";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
export default {
  metaInfo: {
    title: "Focts Login - Seamless Access to Your Productivity Hub",
    titleTemplate: "%s",
    htmlAttrs: {
      lang: "en-US",
    },
    meta: [
      {
        name: "description",
        content:
          "Experience seamless access with Focts Login. Your gateway to efficient productivity solutions. Secure, user-friendly, and ready to elevate your work experience.",
      },
      {
        name: "keywords",
        content:
          "Focts Login, Seamless Access, Productivity Hub, Secure Login, User-Friendly, Efficient Solutions, Elevate Work Experience, Login Portal, Access Now, User Access, Explore Now, Secure and Easy",
      },
    ],
  },
  data: () => ({
    forgotpasswordisable: false,
    timeout: 2000,
    moment: moment,
    forgotpasswordEmailAddress: "",
    current_Timestamp: new Date().getTime(),
    forgotpasswordstatus: false,
    formData: {},
    showSnackBar: false,
    disableSubmit: false,
    snackMessage: "",
    messagecolor: "",
    showpasswordtext: false,
    resendverifyuserid: "",
    disableresendverifybutton: false,
  }),
  mounted() {},
  methods: {
    sendpasswordresetlink() {
      if (this.forgotpasswordisable) {
        return true;
      }
      var self = this;
      this.forgotpasswordisable = true;
      this.$validator.validateAll("forgot-paasword").then((isValid) => {
        if (isValid) {
          var postdata = {
            emailAddress: self.forgotpasswordEmailAddress,
          };
          self.$http
            .post(
              process.env.VUE_APP_ROOT_API +
                "entrance/send-password-recovery-email",
              postdata,
              {
                headers: {
                  "Access-Control-Allow-Origin": "*",
                },
                withCredentials: true,
              }
            )
            .then(() => {
              self.messagecolor = "green";
              self.snackMessage =
                "Password reset link has been sent to your mail successfully";
              self.$nextTick(() => {
                self.showSnackBar = true;
                self.forgotpasswordEmailAddress = "";
                self.$validator.reset();
                self.forgotpasswordisable = false;
              });
            })
            .catch((error) => {
              this.messagecolor = error.response.data.type == 'sendpasswordrecovery'?'green':"red";
              this.snackMessage = error.response.data.message;
              this.forgotpasswordisable = false;
              this.$nextTick(() => {
                this.showSnackBar = true;
              });
            });
        } else {
          this.forgotpasswordisable = false;
        }
      });
    },
    resendVerificationEmail() {
      var self = this;
      if (this.resendverifyuserid) {
        this.disableresendverifybutton = true;
        var url =
          process.env.VUE_APP_ROOT_API +
          "entrance/send-email-verification-token";
        var postdata = {};
        postdata.user_id = this.resendverifyuserid;
        this.$http
          .post(url, postdata)
          .then(() => {
            self.$nextTick(() => {
              self.resendverifyuserid = "";
              self.messagecolor = "green";
              self.snackMessage =
                "We have sent a verification link again, Please verify your email.";
              self.showSnackBar = true;
            });
            setTimeout(() => {
              self.$router.push({
                name: "login",
              });
            }, 2000);
          })
          .catch((error) => {
            self.messagecolor = "red";
            if (error.response.data && error.response.data.message) {
              self.snackMessage = error.response.data.message;
            }
            self.$nextTick(() => {
              self.resendverifyuserid = "";
              self.showSnackBar = true;
            });
          });
      }
    },
    setintolocalstorage(response) {
      var self = this;
      this.$store
        .dispatch("account/storeLoginDetails", response.data)
        .then(() => {
          self.$http.defaults.headers.common["Authorization"] =
            "Bearer " + response.data.token;
          var authuser = JSON.parse(localStorage.getItem("user"));
          localStorage.setItem(
            "tooltipInitial",
            authuser.tooltipDescription === null ||
              authuser.tooltipDescription === undefined
              ? true
              : authuser.tooltipDescription
          );
          localStorage.setItem(
            "dontShowAgain",
            authuser.tooltipTimer === null ||
              authuser.tooltipTimer === undefined
              ? true
              : authuser.tooltipTimer
          );
          if (authuser.isAdmin) {
            window.location.replace("/subscriptionflows");
          } else {
            if (window.history.length > 1 || self.$route.query.to != "") {
              var checkroute = self.$route.query.to ? self.$route.query.to : "";
              if (checkroute.trim() && checkroute.trim() != undefined) {
                var replaceroute = self.$route.query.to;
                if (self.$route.query && self.$route.query.companyId) {
                  replaceroute =
                    self.$route.query.to + "?companyId=" + authuser.companyId;
                }
                window.location.replace(replaceroute);
              } else {
                window.location.replace(
                  "/mywork?companyId=" + authuser.companyId
                );
              }
            } else {
              window.location.replace(
                "/mywork?companyId=" + authuser.companyId
              );
            }
          }
        });
    },
    redirecttosignup() {
      window.location.replace("/signup");
    },
    submit(e) {
      this.miniVariant = false;
      localStorage.setItem("miniVariant", this.miniVariant);
      e.preventDefault();
      var self = this;
      this.$validator.validateAll().then((res) => {
        if (res) {
          this.disableSubmit = true;
          self.formData.companyId = "";
          if (self.$route.query && self.$route.query.to) {
            var getparam = self.$route.query.to
              .substring(self.$route.query.to.indexOf("?") + 1)
              .split("&");
            for (var i = 0, urlqueryparam = {}; i < getparam.length; i++) {
              getparam[i] = getparam[i].split("=");
              urlqueryparam[getparam[i][0]] = decodeURIComponent(
                getparam[i][1]
              );
            }
            self.formData.companyId = urlqueryparam.companyId;
          }
          self.$http
            .put(process.env.VUE_APP_ROOT_API + "entrance/login", self.formData)
            .then((response) => {
              localStorage.setItem("iniatialQuickStart", false);
              if (response.data.user.isAdmin) {
                self.setintolocalstorage(response);
                window.location.replace("/subscriptionflows");
              } else {
                var subscribedstatus = false;
                if (
                  response.data.returncompany.trialcompletedDate >=
                  new Date().getTime()
                ) {
                  subscribedstatus = true;
                } else {
                  if (response.data.returncompany.subscriptionId) {
                    if (response.data.returncompany.subscription) {
                      if (
                        response.data.returncompany.subscription
                          .billing_reason == "admin created subscription"
                      ) {
                        if (
                          moment().unix() >
                            response.data.returncompany.subscription
                              .subscriptionEndTimestamp ||
                          response.data.returncompany.SubscirptionStatus ==
                            "canceled"
                        ) {
                          subscribedstatus = false;
                        } else {
                          subscribedstatus = true;
                        }
                      } else {
                        if (
                          response.data.returncompany.SubscirptionStatus ===
                            "canceled" ||
                          response.data.returncompany.SubscirptionStatus ===
                            "draft" ||
                          response.data.returncompany.SubscirptionStatus ===
                            "active"
                        ) {
                          if (
                            response.data.returncompany.subscription
                              .subscriptionEndTimestamp >= moment().unix() &&
                            response.data.returncompany.subscription
                              .billing_reason == "admin created subscription"
                          ) {
                            subscribedstatus = true;
                          } else {
                            if (
                              response.data.returncompany.SubscirptionStatus ===
                                "active" ||
                              response.data.returncompany.SubscirptionStatus ===
                                "trialing"
                            ) {
                              subscribedstatus = true;
                            }
                          }
                        }
                      }
                    }
                  }
                }
                if (subscribedstatus === true) {
                  self.setintolocalstorage(response);
                } else {
                  this.$store
                    .dispatch("account/storeLoginDetails", response.data)
                    .then(() => {
                      self.$http.defaults.headers.common["Authorization"] =
                        "Bearer " + response.data.token;
                      var authuser = JSON.parse(localStorage.getItem("user"));
                      self.$store.dispatch(
                        "account/getUsersubscriptionStatus",
                        { authuser }
                      );
                      window.location.replace(
                        "/trial-expired?companyId=" +
                          response.data.user.companyId
                      );
                    });
                }
              }
            })
            .catch((error) => {
              self.messagecolor = "red";
              this.disableSubmit = false;
              if (
                error.response &&
                error.response.data &&
                error.response.data.message
              ) {
                self.snackMessage = error.response.data.message;
                if (
                  error.response.data.errortype &&
                  error.response.data.errortype == "verifyerror"
                ) {
                  self.timeout = 4000;
                  self.resendverifyuserid = error.response.data.userid;
                }
                self.$nextTick(() => {
                  self.showSnackBar = true;
                });
              } else {
                self.snackMessage = "Incorrect password";
                self.$nextTick(() => {
                  self.showSnackBar = true;
                  this.disableSubmit = false;
                });
              }
            });
        }
      });
    },
  },
};
</script>

<style scoped>
.pt-4.d-flex.task-titleforgotpassowrd.white:hover {
  cursor: pointer;
  text-decoration: underline;
  color: blue;
}
.task-titleforgotpassowrd:hover {
  cursor: pointer;
  text-decoration: underline;
}
@media (max-width: 991px) {
  .row.login-page {
    flex: unset;
  }
}
</style>
