<template>
  <div>
    <v-row v-if="loadpage" class="register-height">
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="primary d-none d-sm-none d-md-flex align-center justify-center text-center"
      >
        <v-container>
          <router-link to="/">
            <img
              src="@/assets/foctus-logo-whitebg-newbg.png"
              alt="Focts Logo"
              width="300"
              style="border: 0"
            />
          </router-link>
          <div class="taglines pa-1">
            <h4 class="heading text-uppercase white--text">Organize</h4>
            <hr />
            <h4 class="heading text-uppercase white--text">
              INCREASE PRODUCTIVITY
            </h4>
            <hr />
            <h4 class="heading text-uppercase white--text">Manage Team</h4>
          </div>
        </v-container>
      </v-col>
      <v-col
        cols="12"
        class="d-flex d-sm-flex d-md-none align-center justify-center stick-header-logo"
      >
        <router-link
          to="/"
          class="center aligned pa-5 pb-0 text-decoration-none"
        >
          <img
            src="@/assets/logo-new-1-bg.png"
            alt="Focts Logo"
            width="150"
            style="border: 0"
          />
        </router-link>
      </v-col>
      <v-col
        cols="12"
        sm="12"
        md="6"
        class="d-flex align-center auth-right-column mobile-content-logo"
      >
        <v-container v-if="userdetail" class="pa-2 pa-sm-4 pa-md-6">
          <div class="horizontal-dividing-header pa-4 ma-0 pa-sm-0 pa-md-0">
            <h5 class="header mt-0 font-weight-bold">
              Company invitation from
              <span class="text-uppercase primary--text">{{
                formData.companyName
              }}</span>
            </h5>
            <hr class="heading" />
            <small> Accept the invitation to join this company. </small>
          </div>
          <form method="POST">
            <v-row>
              <v-col class="pl-0">
                <v-text-field
                  :error-messages="errors.collect('companyName')"
                  v-model="formData.companyName"
                  v-validate="'required'"
                  data-vv-as="company name"
                  :disabled="disabledCompanyName"
                  label="Company Name"
                  name="companyName"
                  required
                ></v-text-field>
              </v-col>
            </v-row>
            <v-row>
              <v-col class="pl-0" v-if="this.$route.params.companyid">
                <v-text-field
                  v-if="formData.IsManager"
                  value="Manager"
                  :disabled="disabledEmailAddress"
                  v-validate="'required'"
                  label="Role"
                  name="lastName"
                ></v-text-field>
                <v-text-field
                  v-else
                  value="User"
                  :disabled="disabledEmailAddress"
                  v-validate="'required'"
                  label="Role"
                  name="lastName"
                ></v-text-field>
              </v-col>
            </v-row>
            <v-btn
              :loading="disableSubmit"
              :disabled="disableSubmit"
              block
              class="mt-2 gradient-button white--text"
              tile
              color="blue"
              @click="joincompany"
              >join Company</v-btn
            >
          </form>
        </v-container>
      </v-col>
    </v-row>
    <v-snackbar v-model="showSnackBar" :bottom="true" :color="messagecolor">
      {{ snackMessage }}
    </v-snackbar>
    <v-overlay :value="loader">
      <v-progress-circular indeterminate size="45"></v-progress-circular>
    </v-overlay>
  </div>
</template>
<script>
import Vue from "vue";
import VeeValidate from "vee-validate";
Vue.use(VeeValidate);
import moment from "moment";
import timezonemoment from "moment-timezone";
export default {
  $_veeValidate: {
    validator: "new",
  },
  data: () => ({
    userdetail: "",
    timezonemoment: timezonemoment,
    moment: moment,
    loadpage: false,
    loader: false,
    sitekey: process.env.VUE_APP_SITE_KEY,
    dateShowingStatus: false,
    snackMessage: "",
    messagecolor: "",
    showSnackBar: false,
    formData: {
      recaptcha: "",
      firstName: "",
      lastName: "",
      emailAddress: "",
      companyName: "",
      IsManager: false,
      dateOfBirth: "",
    },
    windowHeight: window.innerHeight - 280,
    windowMobileHeight: window.innerHeight - 280,
    windowWidth: window.innerWidth,
    successManger: false,
    manager: "",
    disabledCompanyName: false,
    disabledEmailAddress: false,
    disabledIsManager: false,
    disableSubmit: false,
    dictionary: {
      attributes: {
        first_name: "first name",
        last_name: "last name",
        company_name: "company name",
        password_confirmation: "password",
      },
    },
  }),
  mounted() {
    this.loader = true;
    this.$validator.localize("en", this.dictionary);
    if (this.$route.params.companyid) {
      this.disabledCompanyName = true;
      this.disabledEmailAddress = true;
      this.disabledIsManager = true;
      this.$http
        .get(
          process.env.VUE_APP_ROOT_API +
            "account/get-company-name/" +
            this.$route.params.companyid +
            "/" +
            this.$route.params.token
        )
        .then((res) => {
          this.formData.companyName = res.data.companyName;
          this.formData.emailAddress = res.data.emailAddress.emailAddress;
          this.formData.IsManager = res.data.emailAddress.IsManager
            ? true
            : false;
          this.userdetail = res.data.userdetail;
          this.loader = false;
          this.loadpage = true;
        })
        .catch((error) => {
          if (error.response.data.message == "Invitation deleted") {
            this.$router.push({
              name: "errorpage",
              query: {
                statuscode: "404",
                message: "Your invite has been deleted or it has expired",
              },
            });
          } else {
            this.$router.push({
              name: "login",
            });
          }
        });
    } else {
      this.loadpage = true;
      this.loader = false;
    }
  },
  methods: {
    joincompany(e) {
      var self = this;
      if (this.disableSubmit) {
        return;
      }
      this.disableSubmit = true;
      e.preventDefault();
      var postdata = {
        companyId: self.$route.params.companyid,
        token: self.$route.params.token,
        timezone: self.timezonemoment.tz.guess(),
      };
      self.$http
        .post(process.env.VUE_APP_ROOT_API + "entrance/join-company", postdata)
        .then((response) => {
          this.$store
            .dispatch("account/storeLoginDetails", response.data)
            .then(() => {
              this.$http.defaults.headers.common["Authorization"] =
                "Bearer " + response.data.token;
              var authuser = JSON.parse(localStorage.getItem("user"));
              this.$store.dispatch("account/getUsersubscriptionStatus", {
                authuser,
              });
              localStorage.setItem(
                "tooltipInitial",
                authuser.tooltipDescription === null ||
                  authuser.tooltipDescription === undefined
                  ? true
                  : authuser.tooltipDescription
              );
              localStorage.setItem(
                "dontShowAgain",
                authuser.tooltipTimer === null ||
                  authuser.tooltipTimer === undefined
                  ? true
                  : authuser.tooltipTimer
              );
              var managerStatus = false;
              if (authuser.allcompanies.length > 0) {
                var currentCompanyIndex = authuser.allcompanies.findIndex(
                  (data) => (data.id = authuser.companyId)
                );
                if (currentCompanyIndex != -1) {
                  managerStatus =
                    authuser.allcompanies[currentCompanyIndex].isManager ||
                    authuser.allcompanies[currentCompanyIndex].isAdmin
                      ? true
                      : false;
                }
              }
              if (window.history.length > 1) {
                if (this.$route.query.to && this.$route.query.to != undefined) {
                  window.location.replace(this.$route.query.to);
                } else {
                  window.location.replace(
                    "/mywork?companyId=" + authuser.companyId
                  );
                  if (managerStatus) {
                    localStorage.setItem("iniatialQuickStart", true);
                  }
                }
              } else {
                window.location.replace(
                  "/mywork?companyId=" + authuser.companyId
                );
                if (managerStatus) {
                  localStorage.setItem("iniatialQuickStart", true);
                }
              }
            })
            .catch((error) => {
              this.disableSubmit = false;
              this.messagecolor = "red";
              this.snackMessage = error.response.data.message;
              this.$nextTick(() => {
                this.showSnackBar = true;
              });
            });
        })
        .catch((error) => {
          this.disableSubmit = false;
          this.messagecolor = "red"; 
          this.snackMessage = error.response.data.message;
          this.$nextTick(() => {
            this.showSnackBar = true;
          });
        });
    },
  },
};
</script>

<style>
.rc-anchor-normal,
.rc-anchor-light.rc-anchor-normal {
  width: auto !important;
}
.rc-anchor-normal .rc-anchor-pt {
  right: 4em !important;
}
.error_message {
  color: red;
}

.reCaptcha-Index {
  z-index: 99999;
}
.date-of-birth {
  z-index: 999999 !important;
}
.register-height {
  height: 100vh;
}

@media (max-width: 991px) {
  .register-height {
    height: auto;
  }
}
</style>
